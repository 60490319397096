<template>
  <div class="grid">
    <loading
      :active="isLoadingModel"
      :can-cancel="true"
      color="#274461"
      :is-full-page="fullPage"
    ></loading>
    <Toast />
    <div class="col-12 sticky">
      <div id="custom_card" class="card">
        <div class="grid justify-content-between">
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
            >
              <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                 {{$t('faqs')}}
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
            >
              <Button
                iconPos="right"
                :label="$t('add')" 
                v-tooltip.bottom="$t('add')" 
                icon="pi pi-plus"
                
                @click="openNew"
                size="small"
                style="
                  box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                  border-color: #00b0b0 !important;
                  background-color: #00b0b0 !important;
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card" id="custom_card">
        <div class="grid p-fluid">
          <div class="col-12 md:col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <InputText
                    format="text"
                    v-model="search_filter"
                    @input="search(1)"
                  >
                  </InputText>
                  <label>{{$t('search_faqs')}}</label>
                </span>
              </div>
              <div class="field col-2 md:col-2">
              
                <Button
                  icon="pi pi-refresh"
                 
                  v-tooltip.bottom="$t('refresh')" 
                  @click="search(2)"
                  class="p-button-danger"
                  style=" box-shadow: 0 2px 6px #fd9b96;
                  border-color: #fc544b !important;
                  background-color: #fc544b !important;"
                />
              </div>
            </div>
          </div>
        </div>

        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="PerPageOptions"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :rowHover="true"
          showGridlines
        >
          <template #empty>
            <b style="text-align: center">{{$t('no_rec')}}</b>
          </template>
          <template #header>
            <!-- <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0"><b> FAQs Master</b></h5>
              <Button
                icon="pi pi-refresh"
                v-tooltip.bottom="'Refresh'"
                @click="search(2)"
                style=" box-shadow: 0 2px 6px #fd9b96;
                  border-color: #fc544b !important;
                  background-color: #fc544b !important;"
              />
             
            </div> -->
            <div style="text-align: right; font-weight: bold">
              <span>{{$t('total_faqs')}}:</span>{{ totalRecords }}
            </div>
          </template>
          <Column  :header="$t('title')" style="min-width: 5rem">
            <template #body="{ data }">
              {{ data.name }}
            </template>
          </Column>
          <Column
            
            :header="$t('description')"
            :field="columns[3]"
            style="min-width: 5rem"
          >
            <template #body="{ data }">
              {{ data.description }}
            </template>
          </Column>
          <Column :header="$t('action')" headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                title= "$t('edit_client')" 
               
                v-tooltip.bottom="$t('edit')" 
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
                style="
                    box-shadow: 0 2px 6px #ffc107;
                    border-color: #ffc107 !important;
                    background-color: #ffc107 !important;
                  "
              />
              <Button
                icon="pi pi-trash"
            
                v-tooltip.bottom="$t('delete')" 
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
                style=" box-shadow: 0 2px 6px #fd9b96;
                  border-color: #fc544b !important;
                  background-color: #fc544b !important;"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <Dialog
      v-model:visible="productDialog"
      :breakpoints="{ '960px': '75vw' }"
      :style="{ width: '35vw' }"
     
      :header="$t('faqs')" 

      :modal="true"
      position="top"
    >
      <div class="col-12">
        <div class="p-fluid formgrid grid" style="margin-top: 2%">
          <div class="field col-12 md:col-12" style="margin-top: 1%">
            <span class="p-float-label">
              <InputText id="ticket_title" type="text" v-model="product.name" />
              <label for="name">{{$t('title')}}</label>
            </span>
          </div>
          <div class="field col-12 md:col-12" style="margin-top: 1%">
            <h6 style="color: #6b719b; font-size: 15px; font-weight: 400">
               {{$t('description')}} :
            </h6>
            <Editor v-model="product.description" editorStyle="height: 200px" />
          </div>
        </div>
      </div>

      <template #footer>
        <Button
        :label="$t('save')" 

          @click="save_faq"
          icon="pi pi-check"
          class="p-button-success"
        />
      </template>
    </Dialog>

    <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
         
          :header="$t('Confirm')"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >{{$t('delete_msg')}}<b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
            
              :label="$t('no')" 
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
             
              :label="$t('yes')" 
              icon="pi pi-check"
              class="p-button-text"
              @click="deletefaq"
            />
          </template>
        </Dialog>
  </div>
</template>
  <script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";

import apis from "@/apis";
import axios from "axios";
export default {
  data() {
    return {
        search_filter:"",
      user_key: "",
      page_no: 1,
      isLoading: false,
      PerPageOptions:[10, 50, 100],
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      limit: 10,
      columns: [],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.user_key = localStorage.getItem("user_type");
  },
  async mounted() {
    this.id = this.$route.params.id;
    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  watch: {
      currentLocale(){
     
     
      
    },
     
    },
    computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    }
  },
  methods: {
    async save_faq() 
    {
      var name_regex = /^\s*$/;

      if (!this.product.name) 
      {        
        this.$toast.add({
            severity: "error",
            summary: this.$t('error'),
           // detail: "Please Enter Title",
            detail: this.$t("enter_title"), 
           
            life: 3000,
        });
        return false;
      }
      
      if (this.product.name) 
      {   
        if (name_regex.test(this.product.name)) 
        {
            this.$toast.add({
            severity: "error",
            summary: this.$t('error'),
           // detail: "Please Enter Valid Title",
            detail: this.$t("enter_valid_title"), // Using $t for translation

            life: 3000,
          });
          return false;
        }                
      }

      if (!this.product.description) 
      {       
        this.$toast.add({
            severity: "error",
            summary: this.$t('error'),
            // detail: "Please Enter Description",
            detail: this.$t("enter_description"), 
            life: 3000,
        });
        return false;
      }

      var stripHtmlTags = (html) => {
    return html.replace(/<\/?[^>]+(>|$)/g, ""); // Removes all HTML tags
  };

      let details=
      {
          
          "client_id":localStorage.getItem("client_id"),
          "name":this.product.name,
          //"description":this.product.description,
          "description": stripHtmlTags(this.product.description), 
          "status": "Active",
          "user_id":localStorage.getItem("id"),
          lang:this.$i18n.locale,
    
		}
      if (this.product._id) 
      {
        details['id']=this.product._id;
        
      } 

            this.isLoadingModel=true;
			var promise = apis.addEditFaq(details);
				promise.then((response) => {
            this.isLoadingModel=false;
			//this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
      if (response.data.status==true) 
      {       
        this.$toast.add({
            severity: "success",
            summary: "success",
            // detail: "Please Enter Description",
            detail: response.data.message, 
            life: 3000,
        });
       
      }
      else{
        this.$toast.add({
            severity: "error",
            summary: "error",
            // detail: "Please Enter Description",
            detail: response.data.message, 
            life: 3000,
        });
       

      }
            this.productDialog=false;
            this.get_list();
            this.get_count();
        	});
    },
    async search(count) {
      if (count == 2) {
        this.search_filter="";
      }
      await this.get_list();
      await this.get_count();
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      //  await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      var data = {
        count: true,
        client_id: localStorage.getItem("client_id"),
        search:this.search_filter,
        user_id: localStorage.getItem("id"),
      };
      this.loading = true;
      var promise = apis.getFaqMaster(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.totalRecords = response.data.data;
        if(this.totalRecords>100)
          {
            this.PerPageOptions.push(this.totalRecords);     
          }
        //alert(this.totalRecords);
      });
      console.log(data);
    },
    get_list: function () {
      var data = {
        limit: this.limit,
        page_no: this.page_no,
        count: false,
        client_id: localStorage.getItem("client_id"),
        search:this.search_filter,
        user_id: localStorage.getItem("id"),
      };
      this.loading = true;
      var promise = apis.getFaqMaster(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data.data;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });
      console.log(data);
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    async editProduct(product) {
      this.file_attachment = "";
      this.product = { ...product };
      this.productDialog = true;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deletefaq() {
      if (this.product._id) {
        var data = {
           "client_id": localStorage.getItem("client_id"),
           "id":this.product._id,
           lang:this.$i18n.locale
        };
        this.isLoadingModel = true;
        var promise = apis.deleteFaqMaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.data.status == true) {
            // this.hideDialog1();
           // this.$swal({title:responseapi.data.message , confirmButtonText: this.$t('ok')});
            this.$toast.add({
                      severity: "success", // success, info, warn, error
                      summary: this.$t('success'),
                      detail: responseapi.data.message,
                      life: 3000, // Toast disappears after 3 seconds
                  });
            this.deleteProductDialog = false;
            
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    async exportCSV() {
      this.limit = this.totalRecords;
      this.get_list();
      await this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {  },
};
</script>
  <style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
  